@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../public/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../public/fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'IBM Plex Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf3f3;
}