@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../public/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../public/fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}



html {
    scroll-behavior: smooth;
    font-family: 'IBM Plex Sans' !important;
}

.article-container {
    max-width: 800px;
    font-family: 'IBM Plex Sans' !important;
}

.article-container h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    line-height: 1.2;
}

.article-container h2 {
    font-size: 2em;
    margin: 1.5em 0 0.5em;
}

.article-container h3 {
    font-size: 1.5em;
}

.article-container p {
    line-height: 1.8;
    margin-bottom: 1.5em;
    font-size: 1.1em;
    color: #2c3e50;
}

.article-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 2em auto;
    border-radius: 4px;
}



.article-container code {
    background-color: #f5f5f5;
    border-radius: 4px;
}

.article-container pre {
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
}

.article-container blockquote {
    border-left: 4px solid #3498db;
    margin: 1.5em 0;
    padding: 0.5em 1em;
    background-color: #f8f9fa;
}

.article-container ul,
.article-container ol {
    margin-bottom: 1.5em;
}

.article-container li {
    margin-bottom: 0.5em;
    line-height: 1.6;
}

.article-container a {
    color: #3498db;
    text-decoration: none;
}

.article-container a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .article-container {
        padding: 15px;
    }

    .article-container h1 {
        font-size: 2em;
    }

    .article-container h2 {
        font-size: 1.5em;
    }

    .article-container h3 {
        font-size: 1.2em;
    }

    .article-container p {
        font-size: 1em;
    }
}